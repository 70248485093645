import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';
import SectionPhotoTextComponent from '@/components/section-photo-text/section-photo-text.component';
import SectionPhotoPhotoComponent from '@/components/section-photo-photo/section-photo-photo.component';
import SectionTextTextComponent from '@/components/section-text-text/section-text-text.component';
import SectionTextOnlyComponent from '@/components/section-text-only/section-text-only.component';
import SectionComparativeComponent from '@/components/section-comparative/section-comparative.component';
import SectionImageTextComponent from '@/components/section-image-text/section-image-text.component';
import SectionGaleryComponent from '@/components/section-galery/section-galery.component';
import SectionCollageComponent from '@/components/section-collage/section-collage.component';
import CardProjectRelatedComponent from '@/components/card-project-related/card-project-related.component';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';
import { Project } from '@/class/project';
import { Category } from '@/class/category';

const components: any = {
  SectionPhotoTextComponent,
  SectionTextOnlyComponent,
  SectionComparativeComponent,
  SectionImageTextComponent,
  SectionGaleryComponent,
  SectionPhotoPhotoComponent,
  SectionCollageComponent,
  SectionTextTextComponent,
  CardProjectRelatedComponent
};

@Component({
  components
})
export default class ProjectDetailPage extends Vue {
  
  public loading: boolean = true;
  public project!: Project;
  public related: Project[] = [];

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
    this.getInitData();
  }

  public destroyed() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public getInitData(s: boolean = false) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      const slug = this.$route.params.slug;

      ApiServices.getProjectsBySlug(slug).then((response) => {
        if (response.data) {
          this.project = new Project(response.data.data);

          this.getRelated();
          this.loading = false;
          this.updateTags();
          return resolve(true);
        } else {
          this.$router.push('/404');
        }
      }).catch((error) => {
        if (s) {
          return reject({ redirectTo: '/proyectos' });
        } else {
          this.$router.push('/404');
        }
      });
    });
  }

  public serverPrefetch() {
    return this.getInitData(true);
  }

  public updateTags() {
    this.$options.dunatiHeadTags = {
      title: `Proyectos de Oficina ${this.project.name} - Escritorios y Sillas de Oficina | Dunati`,
      metaTags: [
        // Primaty Tags /
        {
          name: 'description',
          content: this.project.description,
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        // Open Graph / Facebook /
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/proyecto/${this.project.id}`,
        },
        {
          property: 'og:title',
          content: `Proyecto ${this.project.name} - Escritorios y Sillas de Oficina | Dunati`,
        },
        {
          property: 'og:description',
          content: this.project.description,
        },
        {
          property: 'og:image',
          content: `${this.project.img}`,
        },
        // Twitter /
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/productos/${this.project.id}`,
        },
      ],
    };
    // @ts-ignore
    this.renderTags();
  }

  public getRelated() {
    if (!this.project) return;

    const categories = this.project.categories.map((x: Category) => x.id);

    ApiServices.getProjects(`where[categories]=${categories}&where[id][ne]=${this.project.id}&limit=3`).then((pResponse) => {
      if (pResponse.data) {
        this.related = pResponse.data.data;
      }
    })
  }
}

