import { Category } from './category';
import { SectionCollage } from './sectionCollage';
import { SectionComparative } from './sectionComparative';
import { SectionGalery } from './sectionGalery';
import { SectionImageText } from './sectionImageText';
import { SectionPhotoPhoto } from './sectionPhotoPhoto';
import { SectionPhotoText } from './sectionPhotoText';
import { SectionTextOnly } from './sectionTextOnly';
import { SectionTextText } from './sectionTextText';

export class Project {
  public id!: string;
  public name!: string;
  public slug!: string;
  public sub_name!: string;
  public rate!: number;
  public chairs!: string;
  public desk_series!: string;
  public location!: string;
  public meters!: string;
  public logo!: string;
  public img!: string;
  public img_card!: string;
  public description!: string;
  public status!: string;

  public slider: string[] = [];
  public categories: Category[] = [];
  public sections: any[] = [];

  public updateTime!: string;
  public createTime!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.slug = data.slug;
      this.sub_name = data.sub_name;
      this.rate = +data.rate;
      this.chairs = data.chairs;
      this.desk_series = data.desk_series;
      this.location = data.location;
      this.meters = data.meters;
      this.logo = data.logo;
      this.img = data.img;
      this.img_card = data.img_card;
      this.description = data.description;
      this.status = data.status;

      if (data.categories) {
        this.categories =  data.categories.map((x: any) => new Category(x));
      }

      if (data.slider) {
        const entries = Object.entries(data.slider);

        this.slider =  entries.map((x: any) => x[1]);
      }

      if (data.gallery) {
        data.gallery.forEach((element: any) => {
          element.section_type = 'gallery';
          this.sections.push(new SectionGalery(data.gallery));
        })
      }

      if (data.text_only) {
        data.text_only.forEach((element: any) => {
          element.section_type = 'text_only';
          this.sections.push(new SectionTextOnly(element));
        });
      }

      if (data.photo_text) {
        data.photo_text.forEach((element: any) => {
          element.section_type = 'photo_text';
          this.sections.push(new SectionPhotoText(element));
        });
      }

      if (data.comparative) {
        data.comparative.forEach((element: any) => {
          element.section_type = 'comparative';
          this.sections.push(new SectionComparative(element));
        });
      }

      if (data.image_text) {
        data.image_text.forEach((element: any) => {
          element.section_type = 'image_text';
          this.sections.push(new SectionImageText(element));
        });
      }

      if (data.collage) {
        data.collage.forEach((element: any) => {
          element.section_type = 'collage';
          this.sections.push(new SectionCollage(element));
        });
      }

      if (data.photo_photo) {
        data.photo_photo.forEach((element: any) => {
          element.section_type = 'photo_photo';
          this.sections.push(new SectionPhotoPhoto(element));
        });
      }

      if (data.text_text) {
        data.text_text.forEach((element: any) => {
          element.section_type = 'text_text';
          this.sections.push(new SectionTextText(element));
        });
      }

      this.sections = this.sections.sort((a: any, b: any) => parseInt(a.position) - parseInt(b.position));

      this.updateTime = data.createdAt;
      this.createTime = data.updatedAt;
    }
  }
}
